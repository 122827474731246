<template>
  <footer>
    <nav class="footer-nav">
      <font-awesome-icon :icon="['fab', 'github']" />
    </nav>
    <span class="copyright">&copy; 2024, Superpositional</span>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageFooter",
});
</script>

<style scoped>
footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 5rem;
  background-color: #ffffff;
}

.copyright {
  align-self: center;
  padding-bottom: 1rem;
}
</style>
