<template>
  <div id="app">
    <HomePage />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HomePage from "./views/HomePage.vue";

export default defineComponent({
  name: "App",
  components: {
    HomePage,
  },
});
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
body {
  margin: 0;
  padding: 0;
  background-color: #e6f3ff;
}
#app {
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
