<template>
  <div class="home-page">
    <NavBar />
    <div class="title-container">
      <div class="large-title">
        <span class="title-line">Developing niche products;</span>
        <span class="title-line">filling in industry gaps.</span>
      </div>
      <span class="title-about"
        ><b>Superpositional</b> develops open-source, free to use, niche
        software products</span
      >
    </div>
    <PageFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavBar from "../components/NavBar.vue";
import PageFooter from "../components/PageFooter.vue";

export default defineComponent({
  name: "HomePage",
  components: {
    NavBar,
    PageFooter,
  },
});
</script>

<style scoped>
.home-page {
  text-align: center;
  padding: 0;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.large-title {
  font-size: 4rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}

.title-line {
  display: block;
  line-height: 1.2;
}

.title-about {
  display: inline-block;
  padding: 0.5rem 1.5rem;
  margin-top: 2rem;
  font-size: 1.2rem;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.2);
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
}
</style>
