<template>
  <nav class="navbar">
    <div class="navbar-content">
      <div class="navbar-superpositional">
        <img
          src="@/assets/logo.png"
          alt="Superpositonal Logo"
          class="superpositional-logo"
        />
      </div>

      <div class="navbar-options">
        <a href="/products" class="navbar-item">Products</a>
        <a href="/roadmap" class="navbar-item">RoadMap</a>
        <a href="/community" class="navbar-item">Community</a>
        <a href="/contactus" class="navbar-item">Contact Us</a>
      </div>

      <div class="quick-links">
        <a
          href="https://github.com/superpositionalsoftware"
          target="_blank"
          class="github-button"
        >
          <font-awesome-icon :icon="['fab', 'github']" />
        </a>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavBar",
  components: {
    FontAwesomeIcon,
  },
});
</script>

<style scoped>
.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  background-color: #ffffff;
}

.navbar-superpositional {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  margin-left: 1rem;
}

.superpositional {
  font-weight: bold;
  font-size: 2.5rem;
  text-decoration: none;
  color: inherit;
  padding: 0.25rem 0.5rem;
}

.superpositional-logo {
  width: 20rem;
  height: auto;
}

.navbar-options {
  display: flex;
  gap: 3rem;
  flex: 2;
  justify-content: center;
}

.navbar-item {
  text-decoration: none;
  font-weight: bold;
  color: inherit;
}

.quick-links {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
}

.github-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #ffffff;
  font-size: 2rem;
  background-color: #333;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: background-color 0.3s;
}

.github-button:hover {
  background-color: #555;
}
</style>
